import jQuery from 'jquery';
import Swiper from 'swiper'
import { FilterSelectEvent } from './filter-select-event'
import {filterPersonDomElements} from "./person-list-filtering";
import {createInfiniteScrollHandler} from "./infinite-scrolling";
import {Navigation, Manipulation} from "swiper/modules";

export default function InitPersonList() {
  // person filter and search
  const personListElement = document.querySelector('.person-list');
  if (!personListElement) {
    return;
  }

  const infiniteScrollHandler = createInfiniteScrollHandler(personListElement);

  let selectedLocation =
    personListElement.querySelector('select#person-filter-location')?.value ||
    0;
  let selectedDepartment =
    personListElement.querySelector('select#person-filter-department')?.value ||
    0;
  let selectedName =
    personListElement.querySelector('input#person-filter-name')?.value || '';

  /**
   * @type {{name: string, uid: number, root: number}[]}
   */
  let selectedSpecialFachgebiete = [];
  let isSomeSpecialFachgebietSelected = false;

  const filterPersons = () => {
    if (
      (!!selectedLocation && selectedLocation !== '0') ||
      (!!selectedDepartment && selectedDepartment !== '0') ||
      !!selectedName ||
      isSomeSpecialFachgebietSelected
    ) {
      infiniteScrollHandler.destroy();
    }

    const hasAnyMatch = filterPersonDomElements(
      personListElement,
      selectedLocation,
      selectedDepartment,
      selectedName,
      isSomeSpecialFachgebietSelected ? selectedSpecialFachgebiete : []
    )

    personListElement
      .querySelectorAll('.col-no-results')
      .forEach(noResultsElement => noResultsElement.hidden = hasAnyMatch)
  };

  const resetFilters = (type) => {
    jQuery('#person-filter-' + type).each(function () {
      const $filter = jQuery(this)

      if ($filter.is('[data-options')) {
        const currentValue = $filter.val()
        $filter.html($filter.data('options'))
        $filter.val(currentValue)
      } else {
        $filter.attr('data-options', $filter.html())
      }

      $filter.selectmenu('refresh')
    })
  }

  const updateFilter = (type) => {
    resetFilters(type)

    // remove the useless options of the form element of the given type.
    // useless in this context means, that if the option is selected, no results will show up.
    const usefulFilterOptions = new Set()
    usefulFilterOptions.add('0')

    /**
     * @type {NodeListOf<HTMLElement>}
     */
    const listItems = document.querySelectorAll('.doctor__col')

    for (const listItem of listItems) {
      if (!(listItem.offsetWidth > 0 || listItem.offsetHeight > 0 || listItem.getClientRects().length > 0)) {
        continue
      }

      const dataPayload = listItem.dataset[type === 'location' ? 'standorte' : 'fachgebiete']

      if (dataPayload === null || dataPayload === '') {
        continue
      }

      for (const listItemFilterValue of dataPayload.split(',')) {
        usefulFilterOptions.add(listItemFilterValue)
      }
    }

    /**
     * @type {HTMLSelectElement}
     */
    const filterElement = document.querySelector(`select#person-filter-${type}`)
    for (const optionElement of filterElement.querySelectorAll('option')) {
      if (usefulFilterOptions.has(optionElement.value)) {
        continue
      }

      optionElement.remove()
    }

    jQuery(filterElement).selectmenu('refresh')
  }

  jQuery('#person-filter-location').on('selectmenuchange', function () {
    selectedLocation = jQuery(this).val()
    if (selectedLocation === '0') resetFilters('location')
    filterPersons()
    updateFilter('department')
  })

  jQuery('#person-filter-department').on('selectmenuchange', function () {
    // dude, idk. I spent more than an hour trying to figure out how to listen
    // for that dump event, but there's nothing there.
    this.dispatchEvent(new FilterSelectEvent())
    selectedDepartment = jQuery(this).val()
    if (selectedDepartment === '0') resetFilters('department')
    filterPersons()
    updateFilter('location')
  })

  jQuery('#person-filter-name').on('input', function () {
    selectedName = jQuery(this).val().toLowerCase()
    filterPersons()
  })

  const personFilterSpecialFachgebietId = 'person-filter-special-fachgebiet'
  const specialFachgebietFilterInputElement = document.getElementById(personFilterSpecialFachgebietId)
  /**
   * @type {{name: string, uid: number, root: number}[]}
   */
  const originalFachgebieteList = specialFachgebietFilterInputElement !== null ?
    JSON.parse(specialFachgebietFilterInputElement.getAttribute('data-special-fachgebiet-options')) : []

  const specialFachgebietSelectSearchSuggestionIdBase = 'person-filter-special-fachgebiet-suggestion'
  const specialFachgebietSuggestSelectElement = document.getElementById(`${specialFachgebietSelectSearchSuggestionIdBase}`)
  const specialFachgebietSuggestMenuElement = document.getElementById(`${specialFachgebietSelectSearchSuggestionIdBase}-menu`)
  let isSpecialFachgebietAutoCompleteActive = false

  specialFachgebietFilterInputElement?.addEventListener('focusin', () => {
    isSpecialFachgebietAutoCompleteActive = true
    updateFachgebietSelectSearchSuggestionState()
  })

  specialFachgebietFilterInputElement?.addEventListener('focusout', e => {
    if (e.relatedTarget === specialFachgebietSuggestMenuElement) {
      return;
    }

    isSpecialFachgebietAutoCompleteActive = false
    updateFachgebietSelectSearchSuggestionState()
  })

  const $specialFachgebietSelect = jQuery(`#${specialFachgebietSelectSearchSuggestionIdBase}`)

  $specialFachgebietSelect.on('selectmenuchange', function (event, ui) {
    specialFachgebietFilterInputElement.value = event.target.value
    handleSpecialInputChange(event.target.value)
  })

  function updateFachgebietSelectSearchSuggestionState() {
    $specialFachgebietSelect.selectmenu('refresh')
    if (isSpecialFachgebietAutoCompleteActive && selectedSpecialFachgebiete.length !== 0) {
      $specialFachgebietSelect.selectmenu('open')
    } else {
      $specialFachgebietSelect.selectmenu('close')
    }
  }

  function updateFachgebietSelectSearchSuggestion() {
    // remove children
    if(!!specialFachgebietSuggestSelectElement) {
      specialFachgebietSuggestSelectElement.innerText = ''
    }


    if (selectedSpecialFachgebiete.length === 0) {
      return;
    }

    const classNameBase = 'autocomplete-suggestion';

    // if we add something we must add nothing, too
    (() => {
      const element = document.createElement('option')
      element.className = `${classNameBase}`
      element.innerText = ''
      element.setAttribute('data-index', "-1")
      element.value = ''
      specialFachgebietSuggestSelectElement.appendChild(element)
    })()

    // add children
    for (let i = 0; i < selectedSpecialFachgebiete.length; i++) {
      const element = document.createElement('option')
      const item = selectedSpecialFachgebiete[i]
      element.className = `${classNameBase}`
      element.innerText = item.name
      element.setAttribute('data-index', i.toString())
      element.value = item.name
      specialFachgebietSuggestSelectElement.appendChild(element)
    }
  }

  /**
   *
   * @param {string} textInput
   */
  const handleSpecialInputChange = (textInput) => {
    selectedSpecialFachgebiete = []

    if (!!textInput) {
      isSomeSpecialFachgebietSelected = true;
      const lowerCasedValue = textInput.toLowerCase()

      for (let i = 0; i < originalFachgebieteList.length; i++) {
        const currentItem = originalFachgebieteList[i]
        const tokens = currentItem.name.toLowerCase().split(" ")
        const selectedDepartmentInt = parseInt(selectedDepartment)
        const matchesFachbereichFilter = selectedDepartmentInt === 0 || currentItem.root === selectedDepartmentInt
        if (matchesFachbereichFilter && (
          currentItem.name?.toLowerCase().startsWith(lowerCasedValue)
          || anyStartsWith(tokens, lowerCasedValue)
        )) {
          selectedSpecialFachgebiete.push(currentItem)
        }
      }
    } else {
      isSomeSpecialFachgebietSelected = false;
    }

    updateFachgebietSelectSearchSuggestion()
    updateFachgebietSelectSearchSuggestionState()
    filterPersons()
  }

  handleSpecialInputChange(specialFachgebietFilterInputElement?.value)
  jQuery(specialFachgebietFilterInputElement).on('input', function () {
    handleSpecialInputChange(this.value)
  })

  /**
   * Return true, if any item of the strList starts with str.
   *
   * @return {boolean}
   * @param {string[]} strList
   * @param {string} str
   */
  function anyStartsWith(strList, str) {
    for (let i = 0; i < strList.length; i++) {
      if (strList[i].startsWith(str)) {
        return true
      }
    }
    return false
  }

  /**
   * Return true, if base includes any item of check.
   *
   * @template T
   * @param {T[]} base
   * @param {T[]} check
   * @return {boolean}
   */
  function includesAny(base, check) {
    for (let i = 0; i < check.length; i++) {
      if (base.includes(check[i])) {
        return true
      }
    }
    return false
  }

  let personSlides
  let personSlidesContainer
  let personSwiper

  const personListSliders = document.querySelectorAll('.person-list--slider')

  for (const personListSlider of personListSliders) {
    personSlides = personListSlider.querySelector('.swiper-slide')
    personSlidesContainer = personListSlider.querySelector('.swiper-wrapper')
    personSwiper = new Swiper(personListSlider.querySelector('.swiper'), {
      modules: [Navigation, Manipulation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: personListSlider.querySelector('.person-list__next'),
        prevEl: personListSlider.querySelector('.person-list__prev'),
      },
      breakpoints: {
        // mobile
        767: {
          slidesPerView: 2, slidesPerGroup: 2,
        },
        // tablet
        1023: {
          slidesPerView: 3, slidesPerGroup: 3,
        },
      },
      loop: false,
    })

    const listNavigation = personListSlider.querySelector('.person-list__navigation')
    const documentWidth = document.body.clientWidth
    listNavigation.hidden = documentWidth > 1023
      ? personSlides.length <= 3
      : personSlides.length > 4

    const filterPersonsInSlider = () => {
      personSwiper.removeAllSlides()
      for (const personSlide of personSlides) {
        const currentEntryLocations = (personSlide.dataset['standorte'] + '').split(',')

        if (selectedLocation === 0 || selectedLocation === "0" || currentEntryLocations.includes(selectedLocation + '')) {
          personSwiper.appendSlide(personSlide)
        }
      }

      personSwiper.update()

      jQuery('.doctor-links-filter').find('.links-filter__option').removeClass('links-filter__option--selected')
    }

    jQuery('.doctor-links-filter').on('click', '.links-filter__option', function () {
      selectedLocation = jQuery(this).data('uid')

      if (!jQuery(this).hasClass('links-filter__option--selected')) {
        filterPersonsInSlider()
        jQuery(this).addClass('links-filter__option--selected')
      }
    })
  }

  document.querySelectorAll('.doctor__image-container').forEach((element) => {
    const openIcon = element.querySelector('.doctor__open-popup')
    const closeIcon = element.querySelector('.doctor__close-popup')
    const popUp = element.querySelector('.doctor__contact-container')
    const img = element.querySelector('.doctor__image')

    if (openIcon && closeIcon && popUp && img ) {

      const onClick = (e) => {
        popUp.classList.toggle('hidden')
        openIcon.classList.toggle('hidden')
        closeIcon.classList.toggle('hidden')
        img.classList.toggle('opacity')
        e.preventDefault()
      }

      openIcon.addEventListener('click', (e) => onClick(e))
      closeIcon.addEventListener('click', (e) => onClick(e))
    }
  })
}
